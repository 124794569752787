import {UUID} from 'app/utils/uuid';
import {Fragment, FragmentType, InternalReferenceType} from '../../types';
import {AbstractInputFragment} from './abstract-input-fragment';
import {RequiredReferenceCount} from './required-reference-count';

export class ReferenceInputFragment extends AbstractInputFragment {
  private static readonly START_DELIMITER: string = '<';
  private static readonly END_DELIMITER: string = '>';

  public readonly requiredReferenceCount: RequiredReferenceCount;

  public internalReferenceType: InternalReferenceType;

  public referenceInputDisplayType: ReferenceInputDisplayType;

  /**
   * Static factory to create empty ReferenceInputFragments.
   *
   * @returns {ReferenceInputFragment}   The new reference input fragment
   */
  public static empty(
    placeholderValue: string,
    requiredReferenceCount: RequiredReferenceCount,
    internalReferenceType: InternalReferenceType
  ): ReferenceInputFragment {
    return new ReferenceInputFragment(null, placeholderValue, requiredReferenceCount, internalReferenceType, []);
  }

  constructor(
    id: UUID,
    placeholderValue: string,
    requiredReferenceCount: RequiredReferenceCount,
    internalReferenceType: InternalReferenceType,
    children: Fragment[],
    referenceInputDisplayType: ReferenceInputDisplayType = ReferenceInputDisplayType.SECTION_TEMPLATE
  ) {
    super(
      id,
      FragmentType.REFERENCE_INPUT,
      ReferenceInputFragment.START_DELIMITER,
      ReferenceInputFragment.END_DELIMITER,
      placeholderValue,
      children
    );

    this.requiredReferenceCount = requiredReferenceCount;
    this.internalReferenceType = internalReferenceType;
    this.referenceInputDisplayType = referenceInputDisplayType;
  }

  public getPreview(): string {
    return this.displayPlaceholder; // TODO: Correctly implement this method (MD-1877)
  }

  public hasRequiredReferenceCount(): boolean {
    switch (this.requiredReferenceCount) {
      case RequiredReferenceCount.GREATER_THAN_ONE:
        return this.children.length > 1;
      case RequiredReferenceCount.ONE:
        return this.children.length === 1;
      default:
        return false;
    }
  }
}

export enum ReferenceInputDisplayType {
  SECTION_TEMPLATE = 'SECTION_TEMPLATE',
  CLAUSE_TEMPLATE = 'CLAUSE_TEMPLATE',
}
