import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {AltAccessibilityModule} from 'app/accessibility/alt-accessibility/alt-accessibility.module';
import {ContenteditableModule} from 'app/contenteditable.module';
import {DialogModule} from 'app/dialog/dialog/dialog.module';
import {CarsErrorHandlingModule} from 'app/error-handling/cars-error-handling.module';
import {ClauseListComponent} from 'app/fragment/clause-list/clause-list.component';
import {ClauseComponent} from 'app/fragment/clause/clause.component';
import {InlineReferenceFragmentComponent} from 'app/fragment/inline-reference/inline-reference-fragment.component';
import {CarsPermissionPipesModule} from 'app/permissions/pipes/cars-permission-pipes.module';
import {ClauseStylingModule} from 'app/suite-config/clause-styling.module';
import {ShowOnFocusModule} from 'app/utils/directives/show-on-focus.module';
import {EquationModule} from 'app/widgets/equation/equation.module';
import {UserModule} from 'app/widgets/user/user.module';
import {AnchorFragmentComponent} from './anchor/anchor-fragment.component';
import {ClauseGroupFragmentComponent} from './clause-group/clause-group-fragment.component';
import {InputFragmentComponent} from './clause-group/no-op-clause/input-fragment/input-fragment.component';
import {NoOpClauseFragmentComponent} from './clause-group/no-op-clause/no-op-clause-fragment.component';
import {ReadonlyFragmentComponent} from './clause-group/no-op-clause/readonly/readonly-fragment.component';
import {CurrentClauseDirective} from './clause/current-clause.directive';
import {ClauseLinkIconModule} from './clause/gutter-icons/clause-link-icon/clause-link-icon.module';
import {DiscussionsIconModule} from './clause/gutter-icons/discussions-icon/discussions-icon.module';
import {ValidationIconModule} from './clause/gutter-icons/validation-icon/validation-icon.module';
import {LockingOverlayModule} from './clause/locking-overlay/locking-overlay.module';
import {FragmentHostDirective} from './core/fragment-host.directive';
import {EquationFragmentComponent} from './equation/equation-fragment.component';
import {FigureFragmentComponent} from './figure/figure-fragment.component';
import {FragmentIndexModule} from './indexing/fragment-index.module';
import {ReferenceHovertipModule} from './inline-reference/reference-hovertip/reference-hovertip.module';
import {InternalInlineReferenceFragmentComponent} from './inline-section-reference/internal-inline-reference-fragment.component';
import {SectionReferenceHovertipModule} from './inline-section-reference/section-reference-hovertip/section-reference-hovertip.module';
import {ListFragmentComponent} from './list/list-fragment.component';
import {ListHovertipComponent} from './list/list-hovertip/list-hovertip.component';
import {ListItemFragmentDirective} from './list/list-item-fragment.directive';
import {ClauseReferenceInputComponent} from './reference-input/clause-reference-input/clause-reference-input.component';
import {ReferenceInputChildrenComponent} from './reference-input/reference-input-children/reference-input-children.component';
import {ReferenceInputWrapperComponent} from './reference-input/reference-input-wrapper/reference-input-wrapper.component';
import {SectionReferenceInputComponent} from './reference-input/section-reference-input/section-reference-input.component';
import {TableCellFragmentDirective} from './table/table-cell-fragment.directive';
import {TableFragmentComponent} from './table/table-fragment.component';
import {TableRowFragmentDirective} from './table/table-row-fragment.directive';
import {MemoFragmentComponent} from './text/memo-fragment.component';
import {SubscriptFragmentComponent} from './text/subscript-fragment.component';
import {SuperscriptFragmentComponent} from './text/superscript-fragment.component';
import {TextFragmentComponent} from './text/text-fragment.component';
import {UnitInputFragmentComponent} from './unit-input/unit-input-fragment.component';
import {CaptionedHovertipComponent} from './widgets/captioned-hovertip/captioned-hovertip.component';
import {MoveDeleteHovertipComponent} from './widgets/move-delete-hovertip/move-delete-hovertip.component';

const IMPORTS: any[] = [
  CommonModule,
  MatDialogModule,
  DialogModule,
  MatMenuModule,
  MatSnackBarModule,
  MatButtonModule,
  MatIconModule,
  MatTooltipModule,
  CarsErrorHandlingModule,
  ContenteditableModule,
  UserModule,
  ReferenceHovertipModule,
  SectionReferenceHovertipModule,
  AltAccessibilityModule,
  ClauseStylingModule,
  ValidationIconModule,
  DiscussionsIconModule,
  ClauseLinkIconModule,
  LockingOverlayModule,
  CarsPermissionPipesModule,
  FragmentIndexModule,
  ShowOnFocusModule,
  EquationModule,
];

const REFERENCE_INPUT_DECLARATIONS: any[] = [
  SectionReferenceInputComponent,
  ClauseReferenceInputComponent,
  ReferenceInputWrapperComponent,
  ReferenceInputChildrenComponent,
];

const DECLARATIONS: any[] = [
  EquationFragmentComponent,
  FigureFragmentComponent,
  ListFragmentComponent,
  ListHovertipComponent,
  SubscriptFragmentComponent,
  SuperscriptFragmentComponent,
  MemoFragmentComponent,
  TextFragmentComponent,
  TableFragmentComponent,
  FragmentHostDirective,
  ListItemFragmentDirective,
  TableRowFragmentDirective,
  TableCellFragmentDirective,
  CaptionedHovertipComponent,
  MoveDeleteHovertipComponent,
  InlineReferenceFragmentComponent,
  AnchorFragmentComponent,
  ClauseListComponent,
  ClauseComponent,
  CurrentClauseDirective,
  ClauseGroupFragmentComponent,
  NoOpClauseFragmentComponent,
  ReadonlyFragmentComponent,
  InputFragmentComponent,
  UnitInputFragmentComponent,
  InternalInlineReferenceFragmentComponent,
  ...REFERENCE_INPUT_DECLARATIONS,
];

@NgModule({
  imports: IMPORTS,
  declarations: DECLARATIONS,
  exports: DECLARATIONS,
})
export class FragmentModule {}
