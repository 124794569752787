<cars-reference-input-wrapper [content]="content" [showHovertip]="!readOnly">
  <ng-container *ngFor="let groupedClause of groupedClauses; let i = index">
    <span>{{ groupedClause.referencePrefix }}</span>
    <cars-reference-input-children
      [readOnly]="readOnly"
      [content]="groupedClause.children"
      [contentId]="content.id"
      [coordinatingConjunction]="'and'"
    ></cars-reference-input-children>
    <span *ngIf="showSeparator(i)">{{ getSeparator(i) }}</span>
  </ng-container>
  <span>{{ referenceSuffix }}</span>
  <ng-container *ngIf="showDocumentReference"> [{{ content?.children[0] | fragmentIndex : 'Ref ?.?' }}] </ng-container>
</cars-reference-input-wrapper>
